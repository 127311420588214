import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  //首页
  {
    path: "/",
    name: "home",
    // meta:{title:'优制云 - 专为中小微企业打造的一站式智能制造解决方案'},
    component: () => import("@/views/Home.vue"),
  },

  {
    path: "/case",
    name: "case",
    // meta:{title:'成功案例 - 工业互联网SaaS系统成功应用案例'},
    component: () => import("@/views/case/CaseNew.vue"),
  },
  {
    path: "/news",
    name: "news",
    // meta:{title:'行业动态-实时掌握工业互联网行业动态'},
    component: () => import("@/views/news/news.vue"),
 
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/news/details.vue"),
  },

  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/help/AboutUsNew.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/help/contactUsNew.vue"),
  },
  {
    path: "/mes",
    name: "mes",
    component: () => import("@/views/product/mes.vue"),
  },
  {
    path: "/wms",
    name: "wms",
    // meta:{title:'优制云仓储管理 - 高效智能的仓储管理解决方案'},
    component: () => import("@/views/product/wms.vue"),
  },
  {
    path: "/isc",
    name: "isc",
    // meta:{title:'优制云集成供应链ISC - 全流程供应链管理智能化方案'},
    component: () => import("@/views/product/isc.vue"),
  },
  {
    path: "/erp",
    name: "erp",
    // meta:{title:'优制云ERP系统 - 提升企业运营效率的全方位解决方案'},
    component: () => import("@/views/product/erp.vue"),
  },
  {
    path: "/plm",
    name: "plm",
    // meta:{title:'优制云PLM系统 - 加速创新的研发项目管理平台'},
    component: () => import("@/views/product/plm.vue"),
  },
  //
  {
    path: "/hrm",
    name: "hrm",
    // meta:{title:'优制云HRM系统 - 人力资源管理的数字化解决方案'},
    component: () => import("@/views/product/hrm.vue"),
  },
  {
    path: "/crm",
    name: "crm",
    // meta:{title:'优制云客户关系管理CRM - 精准客户管理与业务增长必备工具'},
    component: () => import("@/views/product/crm.vue"),
  },
  {
    path: "/fims",
    name: "fims",
    // meta:{title:'优制云FIMS系统 - 精准高效的企业财务管理平台'},
    component: () => import("@/views/product/fims.vue"),
  },
  {
    path: "/ibms",
    name: "ibms",
    // meta:{title:'优制云智慧园区IBMS - 园区智能化管理中心'},
    component: () => import("@/views/product/ibms.vue"),
  },
  {
    path: "/caseDetail",
    name: "caseDetail",
    component: () => import("@/views/case/details.vue"),
  },
];

const VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(to) { 
  console.log(to)
  return VueRouterPush.call(this, to).catch((err) => err);
};

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  if(_hmt){
    if(to.path){
      _hmt.push(['_trackPageview', '/' + to.fullPath])
    }
  }
  // document.title = to.query.tit || to.meta.title ;
  next();
});

export default router;
