import axios from "axios";
import router from "@/router"; //路由
// create an axios instance
const service = axios.create({
  baseURL: `http://admin.veiban.com`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
 
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      //报错提示
      // Message({
      //   message: res.message || "Error",
      //   type: "error",
      //   duration: 5 * 1000
      // });
    } else {
      return res;
    }
  },
  (error) => {
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000
    // });
    return Promise.reject(error);
  }
);
//HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    // NProgress.done();
    // debugger
    if (
      res.data != undefined &&
      res.data.data != undefined &&
      res.data.data.loginType != undefined
    ) {
      if (res.data.data.loginType == false) {
        router.push({ path: `/login?redirect=${router.fullPath}` });
      }
    }

    const status = Number(res.status) || 200;
    const statusWhiteList = [400] || [];
    const message = res.data.message || "Network error"; //后端返回的message
    //如果在白名单里则自行catch逻辑处理
    if (statusWhiteList.includes(status)) {
      return Promise.reject(res);
    }
    //如果是401则跳转到登录页面
    if (status == 404) {
      router.push({ path: `/login?redirect=${router.fullPath}` }); //并在这里做页面登出操作
    }
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
      // Message({
      //   message: message,
      //   type: "error"
      // });
      return Promise.reject(new Error(message));
    }
    return res;
  },
  (error) => {
    // NProgress.done();
    return Promise.reject(new Error(error));
  }
);

export default service;
