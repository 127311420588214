<template>
  <div class="message">
    <div class="register_try">
      <div class="tit">申请免费试用</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="contactName">
          <el-input
            v-model="ruleForm.contactName"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="请输入11位手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="agree" style="text-align: left">
          <el-checkbox v-model="ruleForm.agree" name="agree"></el-checkbox>
          <span>
            <span style="color: #ffffff; margin: 0 6px">我已阅读并同意</span>
            <span
              style="color: #1584ff; cursor: pointer"
              @click="handleRead('用户协议')"
              >《用户协议》</span
            >
            <span
              style="color: #1584ff; cursor: pointer"
              @click="handleRead('隐私政策')"
              >《隐私政策》</span
            ></span
          >
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn"
            round
            style="width: 100%"
            @click="submitForm('ruleForm')"
            >免费试用</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      style="text-align: left"
      :title="tit_type"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="
        (handleClose) => {
          this.dialogVisible = false;
        }
      "
    >
      <div style="border-top: 1px solid #eee">
        <pre
          v-if="tit_type == '用户协议'"
          style="
            height: 500px;
            overflow: hidden;
            overflow-y: auto;
            text-align: left;
            padding-top: 10px;
          "
        >
提示条款

欢迎您与各伟班经营者（详见定义条款）共同签署本《伟班服务协议》（下称“本协议”）并使用伟班服务！

各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。

【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向伟班客服咨询。

【签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与伟班达成一致，成为伟班“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。

一、定义

伟班：指伟班网站（域名为www.veiban.com）及客户端。

伟班：伟班经营者的单称或合称，指伟班网站经营者深圳优制云工业互联网有限公司。

用户：是指注册、登录、使用伟班的个人或组织。

伟班服务：伟班基于互联网，以包含伟班网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。

伟班规则：包括在所有伟班规则频道内已经发布及后续发布的全部规则、解读、公告等内容以及发布的各类规则、实施细则、产品流程说明、公告等。

伟班规则：伟班规则频道列明的各项规则。

二、 协议范围

2.1 签约主体

【平等主体】本协议由您与伟班经营者共同缔结，本协议对您与伟班经营者均具有合同效力。

【主体信息】伟班经营者是指经营伟班的各法律主体，您可随时查看伟班首页底部公示的证照信息以确定与您履约的伟班主体。本协议项下，伟班经营者可能根据伟班的业务调整而发生变更，变更后的伟班经营者与您共同履行本协议并向您提供服务，伟班经营者的变更不会影响您本协议项下的权益。伟班经营者还有可能因为提供新的伟班服务而新增，如您使用新增的伟班服务的，视为您同意新增的伟班经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。

2.2补充协议

由于互联网高速发展，您与伟班签署的本协议列明的条款并不能完整罗列并覆盖您与伟班所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，伟班隐私权政策、法律声明及伟班规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用伟班服务，视为您同意上述补充协议。

三、 账户注册与使用

3.1 用户资格

您确认，在您开始注册程序使用伟班服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。

3.2 账户说明

【账户获得】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得伟班账户并成为伟班用户。

【账户使用】您有权使用您设置或确认的伟班会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录伟班。

由于您的伟班账户关联您的个人信息及伟班商业信息，您的伟班账户仅限您本人使用。未经伟班同意，您直接或间接授权第三方使用您伟班账户或获取您账户项下信息的行为无效。如伟班判断您伟班账户的使用可能危及您的账户安全或伟班信息安全的，伟班可拒绝提供相应服务或终止本协议。

【账户转让】由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经伟班同意，并符合伟班规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则伟班有权追究您的违约责任，且由此产生的一切责任均由您承担。

【实名认证】作为伟班经营者，为使您更好地使用伟班的各项服务，保障您的账户安全，伟班可要求您按我公司要求及我国法律规定完成实名认证。

3.3 会员名注册规则

会员在选择其伟班会员名、组织机构名、伟班店铺名或域名时应遵守国家法律法规，不得包含违法、涉嫌侵犯他人权利或干扰伟班运营秩序等相关信息。

3.3.1根据国家相关规定，伟班会员名、伟班店铺名及域名不得包含以下信息：

（1）同中华人民共和国的国家名称、国旗、国徽、军旗、勋章相同或者近似的，以及同中央国家机关所在地特定地点的名称或者标志性建筑物的名称、图形相同的；

（2）同外国的国家名称、国旗、国徽、军旗相同或者近似的，但该国政府同意的除外；

（3）同政府间国际组织的旗帜、徽记、名称相同或者近似的，但经该组织同意或者不易误导公众的除外；

（4）与表明实施控制、予以保证的官方标志、检验印记相同或者近似的，但经授权的除外；

（5）同“红十字”、“红新月”的标志、名称相同或者近似的；

（6）同第三方标志相同或者近似的，如：中国邮政、中国电信、中国移动、中国联通、中国网通和中国铁通等；

（7）带有民族歧视性的；

（8）夸大宣传并带有欺骗性的；

（9）有害于社会主义道德风尚或者有其他不良影响的；

（10）县级以上行政区划的地名或者公众知晓的外国地名，不得作为店标，但是，地名具有其他含义的除外；

（11）带有种族歧视、仇恨、性和淫秽信息的语言；

（12）违背公序良俗的不良信息或令人反感的信息；

（13）含有不真实内容或者误导消费者的内容；

（14）其他涉嫌违反法律法规的内容。

3.3.2伟班会员名、组织机构名、伟班店铺名及域名中不得包含干扰伟班运营秩序的信息，包括但不限于以下情形：

（1）会员名、组织机构、店铺名或域名中，包含未经伟班授权、许可使用的名称、标识或其他信息。如：

1)含有“伟班特许”、“伟班授权”及近似含义的词语；

2)“伟班”、\"伟班\"等代表伟班特殊含义的词语或标识；

3)与伟班信用评价相关的词语或标识；

4)深圳市华瀚科技控股有限公司及旗下其他公司的名称或标识；

（2）会员名、组织机构、店铺名或域名中，包含伟班相关机构或组织名义信息，以及虚假的伟班资质或伟班特定服务、活动等信息。如：

1)非商盟店铺的店铺名命名为**商盟，或非商盟的店铺在店铺中使用商盟进行宣传；

2)不具有相关资质或未参加伟班相关活动的会员或店铺，使用与特定资质或活动相关的特定含义的词语，例如：华东区、消费者保障计划、先行赔付等。

（3）其他伟班禁止使用的信息。

3.4注册信息管理

3.4.1 真实合法

【信息真实】在使用伟班服务时，您应当按伟班页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便伟班或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。

【会员名的合法性】您设置的伟班会员名不得违反国家法律法规及伟班规则关于会员名的管理规定，否则伟班可回收您的伟班会员名。伟班会员名的回收不影响您以邮箱、手机号码登录伟班并使用伟班服务。

3.4.2 更新维护

您应当及时更新您提供的信息，在法律有明确规定要求伟班作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，伟班将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。

如伟班按您最后一次提供的信息与您联系未果、您未按伟班的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及伟班造成的全部损失与不利后果。伟班可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部伟班服务，伟班对此不承担责任。

3.5账户安全规范

【账户安全保管义务】您的账户为您自行设置并由您保管，伟班任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户， 并确保您在每个上网时段结束时退出登录并以正确步骤离开伟班。

账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，伟班并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。

【账户行为责任自负】除伟班存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。

【日常维护须知】如发现任何未经授权使用您账户登录伟班或其他可能导致您账户遭窃、遗失的情况，建议您立即通知伟班。您理解伟班对您的任何请求采取行动均需要合理时间，且伟班应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除伟班存在法定过错外，伟班不承担责任。

四、 伟班服务

4.1 客户端服务内容

4.1.1 本服务的具体内容由伟班客户端（包含移动端和PC端）根据实际情况提供，包括但不限于授权用户通过其账号进行即时通讯、传送文件、查阅新闻资讯等。伟班可以对其提供的服务予以变更，且伟班提供的服务内容可能随时变更；用户将会收到伟班关于服务变更的通知。

4.1.2 伟班客户端提供的服务包含企业组织架构，用户即时通讯、群聊，传送文件，考勤打卡等。

4.2伟班网站服务内容

伟班提供的服务内容众多，除客户端的内容外，您还可以用注册账号登录伟班网站（域名为www.epipe.cn）获取额外的服务，具体您可登录伟班浏览，包括但不限于浏览管道行业新闻咨询，下载在线资源，咨询商品，购买商品并进行评价，申请开通店铺，商品或服务的销售与推广等。

4.3费用

伟班为平台向您提供的服务付出了大量的成本，除伟班明示的收费业务外，伟班向您提供的服务目前是免费的。如未来伟班向您收取合理费用，伟班会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。

4.4 责任限制

【不可抗力及第三方原因】伟班依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，伟班并不承担相应的违约责任：

（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；

（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；

（三）在伟班已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。

【海量信息】伟班仅向您提供伟班服务，您了解伟班上的信息系用户自行发布，且可能存在风险和瑕疵。鉴于伟班具备存在海量信息及信息网络环境下信息与实物相分离的特点，伟班无法逐一审查商品或服务的信息，无法逐一审查交易所涉及的商品或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。

【调处决定】您理解并同意，在争议调处服务中，伟班的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。

五、 用户信息的保护及授权

5.1个人信息的保护

伟班非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用伟班提供的服务时，您同意伟班按照在伟班上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。伟班希望通过隐私权政策向您清楚地介绍伟班对您个人信息的处理方式，因此伟班建议您完整地阅读隐私权政策（点击此处或点击伟班首页底部链接），以帮助您更好地保护您的隐私权。

5.2非个人信息的保证与授权

【信息的发布】您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，伟班可对您发布的信息依法或依本协议进行删除或屏蔽。

【禁止性信息】您应当确保您所发布的信息不包含以下内容：

（一）违反国家法律法规禁止性规定的；

（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；

（三）欺诈、虚假、不准确或存在误导性的；

（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；

（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；

（六）存在可能破坏、篡改、删除、影响伟班任何系统正常运行或未经授权秘密获取伟班及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；

（七）其他违背社会公共利益或公共道德或依据相关伟班协议、规则的规定不适合在伟班上发布的。

【授权使用】对于您提供及发布除个人信息外的文字、图片、视频、音频等非个人信息，在版权保护期内您免费授予伟班及其关联公司获得全球排他的许可使用权利及再授权给其他第三方使用的权利。您同意伟班及其关联公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。

六、 用户的违约及处理

6.1 违约认定

发生如下情形之一的，视为您违约：

（一）使用伟班服务时违反有关法律法规规定的；

（二）违反本协议或本协议补充协议（即本协议第2.2条）约定的。

为适应互联网发展和满足海量用户对高效优质服务的需求，您理解并同意，伟班可在伟班规则中约定违约认定的程序和标准。如：伟班可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。

6.2 违约处理措施

【信息处理】您在伟班上发布的信息构成违约的，伟班可根据相应规则立即对相应信息进行删除、屏蔽处理。

【行为限制】您在伟班上实施的行为，或虽未在伟班上实施但对伟班及其用户产生影响的行为构成违约的，伟班可依据相应规则对您执行账户扣分、限制参加营销活动、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，伟班可查封您的账户，终止向您提供服务。

【处理结果公示】伟班可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在伟班上予以公示。

6.3赔偿责任

如您的行为使伟班或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿伟班或其关联公司的上述全部损失。

如您的行为使伟班或其关联公司遭受第三人主张权利，伟班或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。

6.4特别约定

【商业贿赂】如您向伟班或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，伟班可立即终止与您的所有合作并向您收取违约金或赔偿金，该等金额以伟班因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。

【关联处理】如您因严重违约导致伟班终止本协议时，出于维护平台秩序及保护消费者权益的目的，伟班或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第八条约定的方式通知您。

如伟班与您签署的其他协议及伟班或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则伟班出于维护平台秩序及保护消费者权益的目的，可在收到指令时中止甚至终止协议，并以本协议第八条约定的方式通知您。

七、 协议的变更

伟班可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。

如您不同意变更事项，您有权于变更事项确定的生效日前联系伟班反馈意见。如反馈意见得以采纳，伟班将酌情调整变更事项。

如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用伟班服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用伟班服务，则视为您同意已生效的变更事项。

八、 通知

8.1有效联系方式

您在注册成为伟班用户，并接受伟班服务时，您应该向伟班提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。

您在注册伟班用户时生成的用于登陆伟班接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。

伟班将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。

您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过伟班提供的相应的退订功能进行退订。

8.2 通知的送达

伟班通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在伟班公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。

对于在伟班上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在伟班注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。

您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。

您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。

你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。

九、 协议的终止

9.1 终止的情形

【用户发起的终止】您有权通过以下任一方式终止本协议：

（一）在满足伟班公示的账户注销条件时您通过网站自助服务注销您的账户的；

（二）变更事项生效前您停止使用并明示不愿接受变更事项的；

（三）您明示不愿继续使用伟班服务，且符合伟班终止条件的。

【伟班发起的终止】出现以下情况时，伟班可以本协议第八条的所列的方式通知您终止本协议：

（一）您违反本协议约定，伟班依据违约条款终止本协议的；

（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，伟班依据伟班规则对您的账户予以查封的；

（三）除上述情形外，因您多次违反伟班规则相关规定且情节严重，伟班依据伟班规则对您的账户予以查封的；

（四）您的账户被伟班依据本协议回收的；

（五）您有欺诈、发布或销售假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；

（六）其它应当终止服务的情况。

9.2 协议终止后的处理

【用户信息披露】本协议终止后，除法律有明确规定外，伟班无义务向您或您指定的第三方披露您账户中的任何信息。

【伟班权利】本协议终止后，伟班仍享有下列权利：

（一）继续保存您留存于伟班的本协议第五条所列的各类信息；

（二）对于您过往的违约行为，伟班仍可依据本协议向您追究违约责任。

【交易处理】本协议终止后，对于您在本协议存续期间产生的交易订单，伟班可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。

十、 法律适用、管辖与其他

【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例或行业惯例。

【管辖】您因使用伟班服务所产生及与伟班服务有关的争议，由伟班与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。

【可分性】本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。        
        
         </pre
        >
        <pre
          v-if="tit_type == '隐私政策'"
          style="
            height: 500px;
            overflow: hidden;
            overflow-y: auto;
            text-align: left;
            padding-top: 10px;
          "
        >
版本更新日期：2023 年 4 月 6 日

版本生效日期：2023 年 4 月 6 日

引言

深圳优制云工业互联网有限公司旗下的伟班是（包括伟班官方网站（域名为www.veiban.com）及伟班APP客户端，伟班PC客户端、伟班后台管理系统，下称“我们”或“伟班”）非常尊重用户个人信息的保护。本隐私权政策包含了我们收集、存储、使用、共享和保护您的个人信息的条款，我们希望通过本隐私权政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。如您对本隐私权政策有任何疑问，您可以通过伟班公布的联系方式与我们联系。如果您不同意本隐私权政策任何内容，您应立即停止使用伟班服务。当您使用伟班提供的任一服务时，即表示您已同意我们按照本隐私权政策来合法使用和保护您的个人信息。

一、适用范围

(a)在您注册本应用app帐号时，您根据app要求提供的个人注册信息。

(b)在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据。

(c)本应用通过合法途径从商业伙伴处取得的用户个人数据。

(d)本应用严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限，予以封号处理。

二、信息使用

(a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许本应用消息通知。

(b)本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。

三、信息披露

在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：

(a)未经您事先同意，我们不会向第三方披露。

(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息。

(c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露。

(d)如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露。

(e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。

四、我们如何共享信息

我们对您的信息承担保密义务，不会为满足第三方的营销目的而向其出售或出租您的任何信息，我们会在下列情况下才将您的信息与第三方共享：

1.事先获得您的同意或授权。

2.根据法律法规的规定或行政或司法机构的要求。

3.向伟班的关联方分享您的个人信息。

4.向可信赖的合作伙伴提供您的个人信息，让他们根据我们的指示并遵循我们的隐私权政策以及其他任何相应的保密和安全措施来为我们处理这些信息。

5.如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。

6.只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。例如您在伟班上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，伟班会视情况向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。

7.如您出现违反中国有关法律、法规或者伟班相关协议或相关规则的情况，需要向第三方披露。

8.为维护伟班及其关联方或用户的合法权益。

五、Cookie 和网络 Beacon 的使用

为使您获得更轻松的访问体验，您访问伟班相关网站或使用伟班提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。

请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝伟班的Cookie，但这一举动在某些情况下可能会影响您安全访问伟班相关网站和使用伟班提供的服务。

网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络 beacon"），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，我们会通过网络beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。

六、信息存储

伟班收集的有关您的信息和资料将保存在伟班及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或伟班收集信息和资料所在地并在该地被访问、存储和展示。

七、您的个人信息保护

为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。伟班会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，伟班将尽力做到使您的信息不被泄漏、毁损或丢失。

您的账户均有安全保护功能，请妥善保管您的账户及密码信息。伟班将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。

在使用伟班服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络伟班客服，以便伟班采取相应措施。

八、用户信息主体注销账户

您随时可注销此前注册的账户，您可以通过以下方式自行操作：我的 > 账号安全 > 注销账号。

在注销账户之后，我们将停止为您提供产品或服务并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。

九、未成年人保护

我们重视未成年人的个人信息保护，如您为未成年人，建议您请您的监护人仔细阅读本隐私权政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。

十、隐私保护政策的修改

伟班有权随时修改《隐私保护政策》的任何条款，一旦《隐私保护政策》的内容发生变动，伟班将会直接在伟班网站及移动客户端上公布修改之后的《隐私保护政策》，该公布行为视为伟班已经通知您修改内容。伟班也可通过其他适当方式向用户提示修改内容。如果您不同意伟班对本《隐私保护政策》相关条款所做的修改，您有权停止使用伟班服务。如果您继续使用伟班服务，则视为您接受深圳优制云工业互联网有限公司对本协议相关条款所做的修改。

十一、如何联系我们

（一）如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系伟班客服进行反馈；

联系地址：广东省深圳市瀚南山区朗山路16号华瀚创新园A座9楼

开发者名称：深圳优制云工业互联网有限公司

您还可以随时通过访问在线客服系统或拨打我们的任何一部客服电话等多种方式与我们联系。

十二、Android系统应用权限列表：

1.权限名称：存储读写权限

权限代码：

READ_EXTERNAL_STORAGE

WRITE_EXTERNAL_STORAGE

权限功能说明：允许程序读写数据到程序外的存储。

使用场景和目的：外勤打卡时可能需要上传照片文件、审批流程时需要上传附件、公告或审批中需要预览附件等。

2.权限名称：相机权限

权限代码：

CAMERA

权限功能说明：允许程序使用拍照功能。

使用场景：打卡时可能需要拍照，修改头像可能需要拍照，上传附件可能需要拍照，视频会议访问相机等。

3.权限名称：定位权限

权限代码：

ACCESS_FINE_LOCATION

ACCESS_COARSE_LOCATION

权限功能说明：获取GPS、Wi-Fi、基站位置信息。

使用场景：考勤时需要获取地理位置。

4.名称：收集软件安装列表、手机号、AndroidID、MAC等数据

说明：第三方SDK如极光消息推送、腾讯Bugly等所获取的权限

使用场景：仅限第三方SDK为统计数据，实现本身的特定功能等需要，不作为伟班数据保留，也不会进行广告推荐等，详细请参照第三方服务协议

5.名称：录音和蓝牙权限

权限代码：

RECORD_AUDIO

BLUETOOTH_CONNECT

说明：第三方SDK网易云信音视频通话2.0所获取的权限

使用场景：视频会议中进行语音和视频通话需要。

6.名称：位置定位、轨迹记录功能

伟班产品与/或服务为您提供位置定位和轨迹记录服务，在此过程中，我们需要收集您的地理位置，包括如下个人信息：位置信息、行踪轨迹。同时为了收集上述基本的个人位置信息，我们将会申请您的位置权限， 我们收集这些信息是为了向您提供位置定位和轨迹记录服务，如您拒绝提供上述权限将可能导致您无法使用我们的位置定位和轨迹记录服务。

需要说明的是，位置权限是您设备上的一项设置，您可以通过设备设置页面进行管理。但除非您依照法律的规定请求删除了您的个人信息， 否则您在设备上的权限取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。

7.基于伟班产品提供服务自启动和关联启动的功能

当您登录伟班并使用定位轨迹（工作线路）功能时，我们将需要软件在后台自启动或关联启动，或通过安卓/IOS系统发送广播唤醒APP自启动或关联启动，以保证定位轨迹（工作线路）功能正常使用，能形成完整的路线。 如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。自启动权限是您设备上的一项设置，您可以通过设备设置页面进行管理。但除非您依照法律的规定删除了您的个人信息， 否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。

8.基于伟班产品提供服务获取设备MAC地址权限

当您登录伟班时，我们将需要获取您的设备MAC地址的权限，识别登录设备是否变化，用于实现软件内绑定手机的功能以及更换登录设备的识别功能。如您拒绝提供的仅会使您无法使用该功能， 但并不影响您正常使用产品与/或服务的其他功能。MAC地址获取权限是您设备上的一项设置，您可以通过设备设置页面进行管理。但除非您依照法律的规定删除了您的个人信息， 否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。

9.基于伟班产品提供读取应用列表的业务功能

当您登录伟班时，我们将需要获取读取应用列表的权限，识别手机内是否有安装可以更改定位、破坏我们软件定位真实性的app，用于实现保证定位真实的功能、杜绝用户修改真实定位地址。 如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。应用列表获取权限是您设备上的一项设置，您可以通过设备设置页面进行管理。 但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。

十二、Android第三方SDK列表：

1.极光及小米，OPPO，华为，vivo等消息推送

SDK服务协议链接：https://docs.jiguang.cn/compliance_guide/app_compliance_guide/app_compliance_guide1/

华为隐私政策协议：https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177 vivo隐私政策协议：https://dev.vivo.com.cn/documentCenter/doc/652 小米隐私政策协议： https://privacy.mi.com/all/zh_CN/ oppo隐私政策协议： https://open.oppomobile.com/new/developmentDoc/info?id=10288

设备权限：设备信息、地理位置、网络信息、收集软件安装列表、手机号、MAC、设备标识OAID、Android ID、应用包名、版本号和运行状态等个人信息。

使用场景：系统消息推送。

2.百度定位

SDK服务协议链接：https://lbsyun.baidu.com/index.php?title=open/law

设备权限：访问Wi-Fi状态

使用场景：获取连接WIFI信息，返回至百度定位服务端作为辅助定位依据，再返回给开发者更精准的位置信息。

设备权限：位置信息

使用场景：获取连接WI-FI信息，返回至百度定位服务端作为辅助定位依据，再返回给开发者更精准的位置信息。

3.腾讯Bugly统计

SDK服务协议链接：https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf

收集信息：日志信息（包括：第三方开发者自定义日志、Logcat 日志以及 APP 崩溃堆栈信息）、设备 ID（包括：androidid 以及idfv）、软件安装列表、手机号、联网信息、系统名称、系统版本以及国家码。

使用场景：实现各类 Bugly SDK 的特定业务功能。

4.腾讯X5内核

SDK说明：https://x5.tencent.com/docs/questions.html

收集信息：国际设备识别码（IMEI）、国际移动用户识别码（IMSI）、网卡地址（MAC Address）、软件安装列表、手机号、用户机型、屏幕尺寸、操作系统版本号、目标API（targetAPI）、网络类型(ApnType)、网络变化监听广播以及 Wifi的mac地址。

使用场景：实现各类腾讯浏览服务SDK的特定业务功能。

5.网易云信-音视频通话2.0

SDK服务协议链接：https://yunxin.163.com/clauses?serviceType=0

SDK隐私政策链接：https://yunxin.163.com/clauses?serviceType=3

设备权限：录音、蓝牙

使用场景：仅用于视频会议中语音和视频通话的功能支持

注：本隐私政策版本更新日期为 2023 年 4 月 6 日，将于 2023 年 4 月 6 日正式生效。

    
        
         </pre
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { isvalidPhone } from "@/utils/common";
import { leaveMessage } from "@/api/fetch";
import Qs from "qs";
export default {
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入11位手机号"));
      } else if (!isvalidPhone(value)) {
        //判断用户输入的值是否符合规则
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      text: "Copyright© 2024 深圳优制云工业互联网有限公司 版权所有",
      vueShowModel2: false,

      tit_type: "用户协议",
      dialogVisible: false,
      ruleForm: {
        contactName: "",
        contactPhone: "",
        agree: "",
      },
      rules: {
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactPhone: [{ required: true, validator: validPhone }],
        agree: [{ required: true, message: "请勾选协议政策", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleRead(tit) {
      this.dialogVisible = true;
      this.tit_type = tit;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const prams = Qs.stringify(this.ruleForm);
          leaveMessage(prams)
            .then((res) => {
              this.$message.success("留言成功！");
              this.resetForm("ruleForm");
            })
            .catch((e) => {
              console.log(e, 999);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less"  scoped>
.message {
  .btn {
    width: 320px;
    height: 50px;
    border: 1px solid;
    border-color: #1484ff !important;
    border-radius: 25px;
    background: transparent !important;
    color: #1484ff;
  }
  /deep/ .el-input input {
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  }

  .register_try {
    position: absolute;
    right: 240px;
    top: 140px;
    opacity: 0.8 !important;
    width: 400px;
    height: 360px;
    background: #0a1123;
    border-radius: 10px;
    z-index: 99;
    padding: 40px;
    text-align: left;
    .tit {
      font-family: Source Han Sans CN;
      // font-weight: 700;
      color: #ffffff;
      font-size: 30px;
    }
  }
}
</style>
    